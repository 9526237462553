@tailwind base;
@tailwind components;
@tailwind utilities;

.story-line {
    display: flex;
    flex-wrap: wrap; /* Wrap words within the line if they overflow */
    margin-bottom: 0.5rem; /* Add space between lines */
}

.story-word {
    display: inline-block;
    margin-right: 0.25rem; /* Add space between words */
}